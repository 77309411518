<template>
  <v-btn
    v-if="isMaintainer"
    class="green white--text"
    small
    @click="start"
  >
    {{'action.login'| t}}
  </v-btn>
</template>

<script lang="babel" type="text/babel">
import listActionMixins from '@/components/list/listDataAction/listDataAction.js'
export default {
  mixins: [listActionMixins],
  methods: {
    async start() {
      this.$apopup.base({
        title: this.$t('action.login'),
        content: this.$t('member.login.confirm'),
        applyCallback: this.login,
      })
    },
    async login() {
      try {
        const result = await this.$api.collection.memberApi.getMemberToken(this.row.id)
        const url = `${this.frontendDomain}/admin-login?token=${result.token}`
        window.open(url)
      } catch (error) {
        console.error(error)
      }
    },
  },
  computed: {
    isMaintainer() {
      return this.$store.getters['token/isMaintainer']
    },
    frontendDomain() {
      return this.$store.getters['base/frontendDomain']
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>